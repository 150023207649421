import { TaskViewModel } from './../TaskViewModel'
import "./QuickTasking.css"
import QuickTaskingLineItem from "./QuickTaskingLineItem"

const QuickTasking = ({
  vms,
}: {
  vms: TaskViewModel[]
}) => {
  return (
    <div id="quicktasking-container">
      {vms &&
        vms.map((vm) => (
          <QuickTaskingLineItem
            key={vm.listId}
            vm={vm}
          />
        ))}
    </div>
  )
}

export default QuickTasking

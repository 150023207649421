import * as DateEx from '../../../services/DateEx'
import * as T from '../../../Types'
import { TaskViewModel } from './../TaskViewModel'
import './WeeklyQuota.css'
import { useSpring, animated, config } from 'react-spring'
import { useState } from 'react'
import Chart from "react-google-charts"

const WeeklyQuota = ({tasks, hours}: {
    tasks: TaskViewModel[],
    hours: T.WorkHours
}) => {
    // States & data
    // Week days
    const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
    const now = new Date()
    const dayModels = days.map((t,i) => { return {
        label: t,
        status: now.getDay() === i + 1 ? 'now' : now.getDay() > i ? 'past' : 'futur'
    }})

    // Work minutes
    const workHoursStart = new Date(0, 0, 0, hours.workHoursStart, 0, 0, 0)
    const workHoursEnd = new Date(0, 0, 0, hours.workHoursEnd, 0, 0, 0)
    const weeklyMinutes = (DateEx.GetHours(workHoursStart, workHoursEnd).length - 1) * 5

    const timeblockMinutes = tasks?.map(t => t.task.timeblock * (t.task.repeat - t.ran))
    const addgregate = (a:any,b:any) => a + b
    const weeklyMinutesUsed = timeblockMinutes.length !== 0 ? timeblockMinutes.reduce(addgregate) / 60 : 0

    const plannedtimeblockMinutes = tasks?.map(t => t.task.timeblock * t.task.repeat)
    const weeklyMinutesPlanned = plannedtimeblockMinutes.length !== 0 ? plannedtimeblockMinutes.reduce(addgregate) / 60 : 0

    const weeklyPercentPlanned = Math.floor((weeklyMinutesPlanned/weeklyMinutes) * 100)
    const weeklyPercentUsed = Math.floor((weeklyMinutesUsed/weeklyMinutes) * 100)

    // Chart task type distribution today 
    const discoveryCount = tasks?.filter(t => t.task.type.toLowerCase() === 'discovery').length
    const recurrentCount = tasks?.filter(t => t.task.type.toLowerCase() === 'recurrent').length
    const practiceCount = tasks?.filter(t => t.task.type.toLowerCase() === 'practice').length
    const interruptionCount = tasks?.filter(t => t.task.type.toLowerCase() === 'interruption').length
    const weeklyPercentPlannedChartData = [
        ['Week', 'Discovery', 'Recurrent', 'Deliberate Practice', 'Interruptions'],
        ['Week', discoveryCount, recurrentCount, practiceCount, interruptionCount],
      ]
    
    // Styles
    const [flip, set] = useState(false)
    const pulseSpring = useSpring({
        to: { borderColor: '#f4f4f4' },
        from: { borderColor: '#444' },
        reset: true,
        reverse: flip,
        delay: 500,
        config: config.molasses,
        onRest: () => set(!flip),
    })

    return (
        <div>
            <h2>Weekly Quota</h2>
            <div className="days-indicator-container">
                {dayModels.map(t => 
                    <animated.div key={t.label} className={`day-indicator day-indicator-${t.status}`} style={t.status === 'now' ? pulseSpring : undefined}>
                        <p className="day-indicator-label">{t.label}</p>
                    </animated.div>
                )}
            </div>
            <p>{weeklyPercentPlanned}% of your work hours planned ({hours.workHoursStart} to {hours.workHoursEnd} - {weeklyMinutesPlanned}/{weeklyMinutes}hours).</p>
            <Chart
                height={'80px'}
                chartType="BarChart"
                loader={<div>Loading Chart</div>}
                data={weeklyPercentPlannedChartData}
                options={{
                    title: 'Today task types distribution',
                    chartArea: { width: '100%' },
                    legend: { position: 'top', maxLines: 1},
                    isStacked: 'percent'
                }}  // For tests
                rootProps={{ 'data-testid': '3' }}
            />
            <p>{weeklyPercentUsed}% done planned tasks ({weeklyMinutesUsed}/{weeklyMinutes}hours).</p>
        </div>
    )
}

export default WeeklyQuota
import * as T from '../Types'
import * as BaseStarterPackService from './BaseStarterPackService'
import * as LocalStorageApiClient from './LocalStorageApiClient'

/// Administration
export const CleanUpDatabase = async ():Promise<void> => {
  await LocalStorageApiClient.CleanUpDatabase()
}

export const DeleteDatabase = async () => {
  await LocalStorageApiClient.DeleteDatabase()
}

/// Projects
export const GetProjects = async ():Promise<T.Project[]> => {
  return await LocalStorageApiClient.GetProjects()
}

export const AddProject = async (project:T.Project):Promise<T.Project> => {
  return await LocalStorageApiClient.AddProject(project)
}

export const DeleteProject = async (id:string):Promise<boolean> => {
  return await LocalStorageApiClient.DeleteProject(id)
}

export const UpdateProject = async (project:T.Project):Promise<T.Project> => {
  return await LocalStorageApiClient.UpdateProject(project)
}

/// Closed timeblocks
export const GetClosedTimeblocks = async ():Promise<T.ClosedTimeblock[]> => {
  return await LocalStorageApiClient.GetClosedTimeblocks()
}

export const AddClosedTimeblock = async (timeblock:T.ClosedTimeblock):Promise<T.ClosedTimeblock> => {
  return await LocalStorageApiClient.AddClosedTimeblock(timeblock)
}

export const DeleteClosedTimeblock = async (id:string):Promise<boolean> => {
  return await LocalStorageApiClient.DeleteClosedTimeblock(id)
}

export const UpdateClosedTimeblock = async (timeblock:T.ClosedTimeblock):Promise<T.ClosedTimeblock> => {
  return await LocalStorageApiClient.UpdateClosedTimeblock(timeblock)
}

/// Task Pool 
export const GetTask = async (id:string):Promise<T.Task> => {
  return LocalStorageApiClient.GetTask(id)
}

export const GetTasks = async ():Promise<T.Task[]> => {
  return await LocalStorageApiClient.GetTasks()
}

export const AddTask = async (task:T.Task):Promise<T.Task> => {
  return await LocalStorageApiClient.AddTask(task)
}

export const DeleteTask = async (id:string):Promise<boolean> => {
  return await LocalStorageApiClient.DeleteTask(id)
}

export const UpdateTask = async (task:T.Task):Promise<T.Task> => {
  return await LocalStorageApiClient.UpdateTask(task)
}

export const AddOrUpdateAllTasks = async (tasks:T.Task[]):Promise<T.Task[]> => {
  return await LocalStorageApiClient.AddOrUpdateAllTasks(tasks);
}

export const ArchiveTask = async (task:T.Task):Promise<boolean> => {
  return await LocalStorageApiClient.ArchiveTask(task)
}

/// Blueprint
export const GetBlueprints = ():T.Blueprint[] => {
  return BaseStarterPackService.GetBaseStarterPacks()
}

/// User Settings 
export const GetUserSettings = async ():Promise<T.UserSettings> => {
  return await LocalStorageApiClient.GetUserSettings()
}

export const UpdateUserSettings = async (usersettings:T.UserSettings):Promise<T.UserSettings> => {
  return await LocalStorageApiClient.UpdateUserSettings(usersettings)
}

/// Plan State  
export const GetPlanState = async ():Promise<T.PlanState> => {
  return await LocalStorageApiClient.GetPlanState()
}

export const UpdatePlanState = async (planState:T.PlanState):Promise<T.PlanState> => {
  return await LocalStorageApiClient.UpdatePlanState(planState)
}

/// Imports Exports
export const ExportDatabase = async ():Promise<T.Export> => {
  const taskpool = await GetTasks()
  const closedtimeblocks = await GetClosedTimeblocks()
  const projects = await GetProjects()
  const usersettings = await GetUserSettings()

  taskpool.forEach(t => {
    delete t._rev
  })

  closedtimeblocks.forEach(t => {
    delete t._rev
  })

  projects.forEach(t => {
    delete t._rev
  })

  delete usersettings._rev
  
  const result = {
    taskpool,
    closedtimeblocks,
    projects,
    usersettings
  }

  return result
}

export const ImportDatabase = async (exported:T.Export):Promise<boolean> => {

  exported.taskpool.forEach(async t => await AddTask(t))
  exported.closedtimeblocks.forEach(async t => await AddClosedTimeblock(t))
  exported.projects.forEach(async t => await AddProject(t))
  await UpdateUserSettings(exported.usersettings)

  return true
}

export default GetTasks;
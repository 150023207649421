import React, { useState } from 'react'
import { useHistory } from "react-router-dom"
import './AboutThatView.css'

const AboutThatView = () => {
    const history = useHistory()

    const titleMsgs = [
        "You knew it wouldn't work right ?",
        "Hmm... yeah... about that",
        "You weren't supposed to click here !",
        "Got me, there's nothing here yet..."
    ]
    const titleMsg = titleMsgs[Math.floor(Math.random() * titleMsgs.length)]

    const closeMsgs = [
        "Close",
        "You sure ?",
        "What if I told you...",
        "We got cookies",
        "You really sure ?",
        "Those cookies are great",
        "Didn't work, try again",
        "That should've worked",
        "Oh well, I mean",
        "Ok I lied",
        "I can't close myself",
    ]

    const [closeLabel, setCloseLabel] = useState(closeMsgs[0])

    const navigateBack = () => {
        history.goBack()
    }

    const close = () => {
        const msgIndex = closeMsgs.indexOf(closeLabel)

        if (msgIndex < closeMsgs.length-1){
            setCloseLabel(closeMsgs[msgIndex+1])
        }
    }
    
    const navigateFeedback = () => {
        history.push('/feedback')
    }
    
    return (
        <div className='aboutthat-container aboutthat-card'>
            <h1>{titleMsg}</h1>
            <p>This is a prototype with the simple goal of exposing an idea. So, as a prototype, it has LARGE holes in it. And you just found one of them !</p>
            <br />
            <p>Here's your options :</p>            
            <ul>
                <li>Have some feedback ? in the form of a thumbsup, recommendations or even an email.</li>
                <li>Pray to not fall in another hole and navigate back.</li>
                <li>Be vengeful and hit furiously Close to end this mascarade.</li>
            </ul>

            <div className='aboutthat-buttons-container'>
                <button className='button aboutthat-buttons' onClick={navigateFeedback}>Feedback</button>
                <button className='button aboutthat-buttons' onClick={navigateBack}>Go back</button>
                <button className='button aboutthat-buttons' onClick={close}>{closeLabel}</button>
            </div>
        </div>
    )
}

export default AboutThatView
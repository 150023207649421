import { Action, action, Thunk, thunk } from "easy-peasy"
import * as T from "../../Types"
import * as ApiClient from "../../services/ApiClient"
import { TaskViewModel } from "./TaskViewModel"

export interface TaskPoolViewStoreModel {
  // State
  userSettings: T.UserSettings
  taskVms: TaskViewModel[]

  // Thunks
  initialize: Thunk<TaskPoolViewStoreModel>
  saveTasks: Thunk<TaskPoolViewStoreModel, TaskViewModel[]>
  addTask: Thunk<TaskPoolViewStoreModel, T.Task>
  removeTask: Thunk<TaskPoolViewStoreModel, TaskViewModel>
  updateTask: Thunk<TaskPoolViewStoreModel, TaskViewModel>

  // Actions
  setUserSettings: Action<TaskPoolViewStoreModel, T.UserSettings>
  setTasks: Action<TaskPoolViewStoreModel, TaskViewModel[]>
  expandTasks: Action<TaskPoolViewStoreModel, TaskViewModel>
  reduceTasks: Action<TaskPoolViewStoreModel, TaskViewModel>
  inPlaceUpdateTasks: Action<TaskPoolViewStoreModel, TaskViewModel>
}

export const TaskPoolViewStore: TaskPoolViewStoreModel = {
  // Empty states
  taskVms: [],
  userSettings: new T.UserSettings(),

  // Thunks & effects
  initialize: thunk(async (actions) => {
    const userSettings = await ApiClient.GetUserSettings();
    actions.setUserSettings(userSettings);

    const tasks = await ApiClient.GetTasks();
    const closedTimeblocks = await ApiClient.GetClosedTimeblocks();
    const vms = TasksToTaskVMs(tasks, closedTimeblocks);

    actions.setTasks(vms);
  }),

  saveTasks: thunk(async (actions, taskVms:TaskViewModel[]) => {
    const closedTimeblocks = await ApiClient.GetClosedTimeblocks();
    const tasks = taskVms.map((t:TaskViewModel) => t.task); 
    const updatedTasks = await ApiClient.AddOrUpdateAllTasks(tasks);
    const vms = TasksToTaskVMs(updatedTasks, closedTimeblocks);
    actions.setTasks(vms);
  }),

  addTask: thunk(async (actions, task:T.Task) => {
    const newTask = await ApiClient.AddTask(task);
    const taskVM = TaskToTaskVM(0, newTask, []);
    actions.expandTasks(taskVM);
  }),

  removeTask: thunk(async (actions, taskVM:TaskViewModel) => {
    await ApiClient.DeleteTask(taskVM.task._id!);
    actions.reduceTasks(taskVM);
  }),

  updateTask: thunk(async (actions, taskVM:TaskViewModel) => {
    const updatedTask = await ApiClient.UpdateTask(taskVM.task);
    const newTaskVM = TaskToTaskVM(0, updatedTask, []);
    actions.inPlaceUpdateTasks(newTaskVM);
  }),

  // Actions
  setUserSettings: action((state, userSettings) => {
    state.userSettings = userSettings
  }),
  setTasks: action((state, taskVMs) => {
    state.taskVms = taskVMs;
  }),
  expandTasks: action((state, taskVM) => {
    taskVM.listId = state.taskVms.length;
    state.taskVms = [...state.taskVms, taskVM];
  }),
  reduceTasks: action((state, taskVM) => {
    state.taskVms = state.taskVms.filter((tvm) => tvm.listId !== taskVM.listId);
  }),
  inPlaceUpdateTasks: action((state, taskVM) => {
    const oldVM = state.taskVms.filter((tvm) => tvm.task._id === taskVM.task._id)[0];
    const update = state.taskVms.filter((tvm) => tvm.task._id !== taskVM.task._id);
    taskVM.listId = oldVM.listId;
    state.taskVms = [...update, taskVM];
  }),
};

function TasksToTaskVMs(tasks: T.Task[], closedTimeblocks: T.ClosedTimeblock[]) {
  return tasks
    .sort((a, b) => a.priority - b.priority)
    .map((t, i) => TaskToTaskVM(i,t, closedTimeblocks))
}

function TaskToTaskVM(listId: number, t: T.Task, closedTimeblocks: T.ClosedTimeblock[]){
  const vm: TaskViewModel = {
    listId: listId,
    ran: closedTimeblocks.filter((ctb) => ctb.taskId === t._id && ctb.status === T.TaskStatus.done).length,
    isCreationValidated: true,
    depth: 0,
    task: t,
    done: false,
  }
  vm.done = vm.ran === t.repeat
  return vm
}
import * as T from '../Types'

const BlueprintListItem = ({blueprint, onAdd}:{
    blueprint: T.Blueprint
    onAdd: (id:string) => void
}) => {
    return (
        <div className='blueprintslist-item' onClick={() => onAdd(blueprint._id!)}>
            <div className='img-center-cropped' style={{ backgroundImage: `url(${blueprint.imgUrl})` }}/>
            
            <h3>{blueprint.name}</h3>
            <p>{blueprint.description}</p>
        </div>
    )
}

export default BlueprintListItem
import * as ApiClient from './ApiClient'
import * as T from '../Types'
import * as DateEx from './DateEx'

// Logic splited by those types: orchestrators, generators and storers functions.
// Generators : self contained code; all dependencies are injected by parameters; 
//   doesn't read/write/access any api or state; return result without storing it.
// Orchestrators : execution parameters are injected by parameters; dependencies 
//   or state are accessed in read only mode within it; gather/send data from/to 
//   generators; return the result without storing it.
// Storers : no generations or data transformations, only passing needed execution
//   parameters to generators and storing the result; so doesn't read, but write; 
//   return the final result without modifying it.

export const CloseDay = async ():Promise<void> => {
    const planState = await (await ApiClient.GetPlanState())
    const timeblocks = planState.timeblocks

    const closeTimeblocks = timeblocks.map((t) => {
        delete t._id
        delete t._rev
        t.status = t.status === 'done' ? 'closed' : 'failed'
        return t
    })
    console.log(`Closing ${closeTimeblocks.length} timeblocks`)

    for (let i = 0; i < closeTimeblocks.length; i++) {
        await ApiClient.AddClosedTimeblock(closeTimeblocks[i])
    }

    planState.timeblocks = [];
    ApiClient.UpdatePlanState(planState);
}

export const RegenerateTodayFromNowTimeblocks = async ():Promise<T.PlanState> => {
    const planState = await (await ApiClient.GetPlanState())
    const userSettings:T.UserSettings = await ApiClient.GetUserSettings()
    const nowHour = new Date().getHours()

    // Clean undone timeblock
    // const oldTimeblocks = await ApiClient.GetTimeblocks()
    // const timeblocksToDelete = oldTimeblocks.filter(t => t.status !== 'done')
    // for (let i = 0; i < timeblocksToDelete.length; i++) {
    //     const idToDelete = timeblocksToDelete[i]._id
    //     if (idToDelete){
    //         await ApiClient.DeleteTimeblock(idToDelete)
    //     }
    // }

    // Regenerate new timeblocks
    const newTimeblocks = await OrchestrateTimeblocksFromHours(nowHour, userSettings.preferedWorkHoursEnd)
    
    // Filler white timeblock
    const fillerTimeblocks:T.Timeblock[] = []
    for (let i = 0; i < nowHour - userSettings.preferedWorkHoursStart; i++){
        fillerTimeblocks.push({
            name: '',
            description: '',
            type: '',
            timeblock: 60,
            status: 'filler',
            taskId: '',
            order: 0,
            repeat: 0,
            weekNumber: 0,
            dayNumber: 0,
        } as T.Timeblock)
    }

    // Concatene all
    const alltimeblocks = [...fillerTimeblocks, ...newTimeblocks.timeblocks]
    // Find the first real timeblock en set it to in progress
    const [firstTimeblocks] = alltimeblocks.filter(t => t.status !== 'filler')
    firstTimeblocks.status = 'inprogress'
    // Generate order
    alltimeblocks.forEach(async (t, i) => { 
        t.order = i
    })

    planState.timeblocks = alltimeblocks

    const result = await ApiClient.UpdatePlanState(planState)
    return result
}

export const OrchestrateTimeblocksFromHours = async (hoursStart:number, hoursEnd:number):Promise<T.PlanState> => {
    // Orchestrator code
    const tasks:T.Task[] = await ApiClient.GetTasks()
    const closedTimeblocks:T.ClosedTimeblock[] = await ApiClient.GetClosedTimeblocks()
    const userSettings = await ApiClient.GetUserSettings()
    const planState = await ApiClient.GetPlanState();

    // Arguments validation
    if (hoursEnd < hoursStart){ // Only end of day
        hoursEnd = 23;
    }

    console.log('Orchestrate timeblocks from hours start: ' + hoursStart + ' hours end: ' + hoursEnd);

    var timeblocks:T.Timeblock[] = [];
    if (userSettings.autoAddLunchTime)
    {
        // Calculate AM
        let morningTimebudget:T.Timeblock[] = []
        if(hoursStart < 13) {
            morningTimebudget = await GenerateTimeblockForHours(hoursStart, hoursEnd < 12 ? hoursEnd : 12, tasks, closedTimeblocks)
            if (hoursEnd > 12) {
                morningTimebudget.push({
                        name: 'Lunch',
                        status: 'planned',
                        description: 'Eat & Read',
                        type: 'reccurent',
                        timeblock: 60,
                        start:12,
                        taskId: '-1',
                        order: 0,
                        weekNumber: DateEx.GetWeekNumber(),
                        dayNumber: DateEx.GetDayOfYear()
                    } as T.Timeblock)
            }
        }

        // Filter out used Tasks that were not allocated yet
        const remainingAllocatableTasks = tasks.filter((t) => morningTimebudget.filter((tb) => t._id === tb.taskId).length === 0)

        // Calculate PM
        let afternoonTimebudget:T.Timeblock[] = []
        if(hoursEnd > 12){
            const startPMHour = hoursStart < 13 ? 13 : hoursStart
            afternoonTimebudget = await GenerateTimeblockForHours(startPMHour, hoursEnd, remainingAllocatableTasks, closedTimeblocks)
        }

        timeblocks = [...morningTimebudget, ...afternoonTimebudget]
    } else {
        console.log(hoursEnd)
        timeblocks = await GenerateTimeblockForHours(hoursStart, hoursEnd, tasks, closedTimeblocks)
    }
    
    // Mark the first timeblock in progress
    if (timeblocks.length > 0){
        timeblocks[0].status = 'inprogress'
    }

    timeblocks.forEach((t, i) => { t.order = i })

    planState.hoursStart = hoursStart;
    planState.hoursEnd = hoursEnd;
    planState.timeblocks = timeblocks;
    
    const {result} = await StoreTimeblocksFromHours(planState, timeblocks, userSettings)
    return result
}

export const GenerateTimeblockForHours = async (start: number, end: number, tasks:T.Task[], closedTimeblocks:T.ClosedTimeblock[]):Promise<T.Timeblock[]> => {
    /// Generators code    
    let workMinutes = (end - start) * 60;
    const currentWeekNumber = DateEx.GetWeekNumber();
    const currentDayNumber = DateEx.GetDayOfYear();

    console.log(`Generating timeblocks for ${workMinutes} minutes from a pool of ${tasks.length} and ${closedTimeblocks.length} closed timeblocks`)
    console.log(`Time windows is : ${start}h to ${end}h`);

    // First let's order tasks that we have and them create corresponding timeblock candidates
    const taskblocks = tasks.sort((a:any, b:any) => {
        return b.priority - a.priority
    })

    // Building the pool of timeblocks and other data
    const allocatableTasks = taskblocks.filter(t => t.timeblock <= workMinutes)
    const allocatableTasksWithPreviousRun = allocatableTasks.map(t => {
        const previousRuns = closedTimeblocks.filter((prev) => prev.taskId === t._id && prev.status === 'closed')
        const timeblock = T.CreateTimeblockFromTask(t)
        timeblock.dayNumber = currentDayNumber
        timeblock.weekNumber = currentWeekNumber

        return { 
            task: t, 
            ran: previousRuns.length, 
            closedTimeblocks: previousRuns,
            timeblock: timeblock 
        }
    }).filter(t => t.task.repeat > t.ran && t.closedTimeblocks.filter(tt => tt.dayNumber === currentDayNumber).length === 0)

    console.log(`Final filtered pool size ${allocatableTasksWithPreviousRun.length}`)

    const timebudget:T.Timeblock[] = []
    // Then try to fit in our day
    const pool = allocatableTasksWithPreviousRun
    for (let i = pool.length - 1; i >= 0 ; i--) {
        const candidate = pool[i]

        // The candidate is prime to be added with no need to assess other candidates
        if (candidate.ran === 0 && workMinutes >= candidate.task.timeblock){
            console.log(candidate.task.name + ' norun added')
            timebudget.push(candidate.timeblock)
            pool.splice(pool.indexOf(candidate), 1)
            workMinutes -= candidate.task.timeblock
            continue
        }
        // If the candidate was already ran we need to assess other candidates first
        if (candidate.ran > 0){
            console.log(candidate.task.name + ' ' + candidate.ran + ' run')
            const [lessRanTimeblock] = pool.filter(t => t.task.priority === candidate.task.priority 
                                                        && t.ran < candidate.ran
                                                        && workMinutes >= t.task.timeblock)

            if (lessRanTimeblock !== undefined){
                console.log(candidate.task.name + ' discarded for ' + lessRanTimeblock.task.name)
                // Replace the candidate with the one less ran
                pool.splice(pool.indexOf(lessRanTimeblock), 1)
                pool.push(candidate)
                timebudget.push(lessRanTimeblock.timeblock)
                workMinutes -= lessRanTimeblock.task.timeblock
            } else if (workMinutes >= candidate.task.timeblock) {
                // There was no other better candidate to fit in than the current one
                console.log(candidate.task.name + ' ran but added ')
                pool.splice(pool.indexOf(candidate), 1)
                timebudget.push(candidate.timeblock)
                workMinutes -= candidate.timeblock.timeblock
            }
            continue
        }
        // We failed to add the current candidates because of workMinutes left.
    }

    return timebudget
}

export const StoreTimeblocksFromHours = async (planState:T.PlanState, newTimeblocks:T.Timeblock[], userSettings:T.UserSettings):Promise<{result: T.PlanState; userSettings:T.UserSettings;}> => {
    // Storer code, write api access
    let currentTodayState = await ApiClient.GetPlanState();
    currentTodayState.hoursStart = planState.hoursStart;
    currentTodayState.hoursEnd = planState.hoursEnd;
    currentTodayState.timeblocks = planState.timeblocks
    var state = await ApiClient.UpdatePlanState(currentTodayState);

    var resultSettings = await ApiClient.UpdateUserSettings(userSettings)
    return {result:state, userSettings:resultSettings}
}
import ComingSoonCard from './components/ComingSoonCard'

const ComingSoonView = () => {
    
    return (
        <div className="login-container">
            <ComingSoonCard />
        </div>
    )
}

export default ComingSoonView
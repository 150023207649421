import { NowViewStoreModel, NowViewStore } from './views/Now/NowViewStore'
import { TaskPoolViewStore, TaskPoolViewStoreModel } from './views/TaskPool/TaskPoolViewStore'

export interface StoresModel {
  NowView: NowViewStoreModel
  TaskPoolView: TaskPoolViewStoreModel
}

export const Stores: StoresModel = {
    NowView: NowViewStore,
    TaskPoolView: TaskPoolViewStore
  }

export default Stores;
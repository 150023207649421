import { useState, useEffect } from "react"
import * as ApiClient from "../../services/ApiClient"
import * as T from "../../Types"
import './TaskPoolReview.css'

export interface TaskPoolReviewViewModel {
  listId: number
  task: T.Task
  closedTimeblocks: T.ClosedTimeblock[]
}

const TaskPoolReview = () => {
  const [currentTaskVM, setCurrentTaskVM] = useState<TaskPoolReviewViewModel>()

  useEffect(() => {
    const fetchTaskPool = async () => {
      const vms: TaskPoolReviewViewModel[] = []
      const tasks = await ApiClient.GetTasks()
      const closedTimeblocks = await ApiClient.GetClosedTimeblocks()

      tasks.forEach((task, i) => {
        const taskClosedTimeblocks = closedTimeblocks.filter(
          (t) => t.taskId === task._id
        )
        const currentVM: TaskPoolReviewViewModel = {
          listId: i,
          task: task,
          closedTimeblocks: taskClosedTimeblocks,
        }

        // If the task is not done dont review it
        if (currentVM.closedTimeblocks.length === currentVM.task.repeat) {
          vms.push(currentVM)
        }
      })

      setCurrentTaskVM(vms[0])
    }

    fetchTaskPool()
  }, [])

  return (
    <div>
        {currentTaskVM && (
            <>
                <h1>{currentTaskVM.task.name}</h1>
                <h2>Done {currentTaskVM.closedTimeblocks.length} times</h2>
                <h2>Some cool calendar graph from google ? here </h2>

                <div className="repeat-section">
                    <h2>Change priority {currentTaskVM.task.priority}</h2>
                    <input
                    type="submit"
                    className="button button-sub-header2"
                    value="+1"
                    />
                    <input
                    type="submit"
                    className="button button-sub-header2"
                    value="-1"
                    />
                </div>

                <div className="repeat-section">
                    <h2>Increase session number</h2>
                    <input
                    type="submit"
                    className="button button-sub-header2"
                    value="+1"
                    />
                    <input
                    type="submit"
                    className="button button-sub-header2"
                    value="+3"
                    />
                    <input
                    type="submit"
                    className="button button-sub-header2"
                    value="+5"
                    />
                    <input
                    type="submit"
                    className="button button-sub-header2"
                    value="Add custom"
                    />
                </div>

                <div className="repeat-section">
                    <h2>Change timeblock min</h2>
                    <input
                    type="submit"
                    className="button button-sub-header2"
                    value="-30 min"
                    />
                    <input
                    type="submit"
                    className="button button-sub-header2"
                    value="-15 min"
                    />
                    <input
                    type="submit"
                    className="button button-sub-header2"
                    value="+15 min"
                    />
                    <input
                    type="submit"
                    className="button button-sub-header2"
                    value="+30 min"
                    />
                    <input
                    type="submit"
                    className="button button-sub-header2"
                    value="Add custom"
                    />
                </div>
            </>
        )}
    </div>
  )
}

export default TaskPoolReview

import { useState, useEffect } from 'react'
import './TimeblockDetails.css'
import * as T from '../Types'

interface Props {
    timeblock: T.Timeblock
    updateTimeblock: (timeblock:T.Timeblock) => void
    gotoTaskDetails: (id:string) => void
}

const TimeBlockDetails = ({timeblock, updateTimeblock, gotoTaskDetails}:Props) => {
    const [nameEdit, setNameEdit] = useState('')
    const [descriptionEdit, setDescriptionEdit] = useState('')
    const [isEditTitleMode, setIsEditTitleMode] = useState(false)
    const [isEditBodyMode, setIsEditBodyMode] = useState(false)

    const editNameMode = () => {
        setNameEdit(timeblock.name)
        setDescriptionEdit(timeblock.description)
        setIsEditTitleMode(true)
        setIsEditBodyMode(false)
    }
    
    const editDescriptionMode = () => {
        setNameEdit(timeblock.name)
        setDescriptionEdit(timeblock.description)
        setIsEditBodyMode(true)
        setIsEditTitleMode(false)
    }

    const editOut = () => {
        updateTimeblock({...timeblock, name: nameEdit, description: descriptionEdit})
        
        setIsEditBodyMode(false)
        setIsEditTitleMode(false)
        
        setNameEdit('')
        setDescriptionEdit('')
    }

  // Auto height for textarea
  useEffect(() => {
    const updateTxtArea = (ev:any) => {
           ev.srcElement.style.height = "auto"
            ev.srcElement.style.height = ev.srcElement.scrollHeight + "px"
    }
    
    const tx = document.getElementsByClassName("timeblock-details-body-textarea")[0]
    if (tx) {
      tx.setAttribute(
        "style",
        "height:" + tx.scrollHeight + "px;overflow-y:hidden;"
      )
      tx.addEventListener("input", updateTxtArea, false)
    }
  }, [isEditBodyMode])

    return (
        <div className='timeblock-details-container'>
            <div className='timeblock-details-header'>
                {!isEditTitleMode ? (
                    <h1 onDoubleClick={editNameMode}>{timeblock.name}</h1>
                ) : (
                    <textarea className='timeblock-details-title-textarea'
                            value={nameEdit} 
                            onChange={(e) => setNameEdit(e.target.value)}
                            onBlur={editOut}/>
                )}
                <input type='submit' className='button button-sub-header1' onClick={() => gotoTaskDetails(timeblock.taskId) } value='Go to Task'/>
            </div>

            {!isEditBodyMode ? 
                timeblock.description.split("\n").map((value, index) => {
                    return (
                      <div onDoubleClick={editDescriptionMode} key={index}>
                        <label>{value}</label>
                        <br />
                      </div>
                    );}
            ) : (
                <textarea className='timeblock-details-body-textarea'
                          value={descriptionEdit}
                          onChange={(e) => setDescriptionEdit(e.target.value)}
                          onBlur={editOut}/>
            )}
        </div>
    )
}

export default TimeBlockDetails

import { useState, useEffect } from 'react'
import * as ApiClient from '../../services/ApiClient'

import Grid from "@material-ui/core/Grid";

import Button from "@material-ui/core/Button"
import ReactJson from 'react-json-view'

import "./DebugView.css"

const DebugView = () => {
    // States
    const [viewJson, setViewJson] = useState<any>();

    // Effects

    // Actions
    const loadTaskDatabase = async (e:any) => {
        const data = await ApiClient.GetTasks();
        setViewJson(data);
    }

    const loadTimeTodayState = async (e:any) => {
        const data = await ApiClient.GetPlanState();
        setViewJson(data);
    }
    
    const loadSettingsDatabase = async (e:any) => {
        const data = await ApiClient.GetUserSettings();
        setViewJson(data);
    }
    
    const loadClosedDatabase = async (e:any) => {
        const data = await ApiClient.GetClosedTimeblocks();
        setViewJson(data);
    }

    return (
        <div className='container'>
            <h2>Debug</h2>
            <div className='localdatabase-container'>
                <h2>Local Database</h2>
                <Grid>
                    <Grid item xs={3} sm={6} md={6}>
                        <Button onClick={loadTaskDatabase}>Tasks</Button>
                        <Button onClick={loadTimeTodayState}>TodayState</Button>
                        <Button onClick={loadSettingsDatabase}>Settings</Button>
                        <Button onClick={loadClosedDatabase}>Closed</Button>
                    </Grid>
                    <Grid>
                        <ReactJson src={viewJson} />
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

export default DebugView

import { Action, action, Thunk, thunk } from "easy-peasy"
import * as T from "../../Types"
import * as ApiClient from "../../services/ApiClient"

export interface NowViewStoreModel {
  // State
  sessions: T.Task[]
  userSettings: T.UserSettings
  planState: T.PlanState

  // Thunks
  fetchState: Thunk<NowViewStoreModel>
  saveTodayState: Thunk<NowViewStoreModel, T.PlanState>
  closeTimeblock: Thunk<NowViewStoreModel, T.Timeblock>

  // Actions
  setUserSettings: Action<NowViewStoreModel, T.UserSettings>
  setTodayState: Action<NowViewStoreModel, T.PlanState>
}

export const NowViewStore: NowViewStoreModel = {
  // Empty states
  sessions: [],
  userSettings: new T.UserSettings(),
  planState: new T.PlanState(),

  // Thunks Loading
  fetchState: thunk(async (actions) => {
    const userSettings = await ApiClient.GetUserSettings()
    actions.setUserSettings(userSettings)

    const todayState = await ApiClient.GetPlanState()
    actions.setTodayState(todayState)
  }),

  saveTodayState: thunk(async (actions, state:T.PlanState) => {
    const result = await ApiClient.UpdatePlanState(state);
    actions.setTodayState(result);
  }),

  closeTimeblock: thunk(async (actions, timeblock, stateHelper) => {
    const closed = T.CreateClosedTimeblockFromTimeblock(timeblock);
    await ApiClient.AddClosedTimeblock(closed);

    const state = stateHelper.getState().planState;
    if (state.timeblocks.indexOf(timeblock) + 1 > state.timeblocks.length - 1) {
      return
    }
    const next = state.timeblocks[state.timeblocks.indexOf(timeblock) + 1]
    next.status = T.TaskStatus.inprogress

    await actions.saveTodayState(state);
  }),

  // Actions
  setUserSettings: action((state, userSettings) => {
    state.userSettings = userSettings
  }),
  setTodayState: action((state, todayState) => {
    state.planState = todayState;
  })
}

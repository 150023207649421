import { useState, useEffect, KeyboardEvent } from 'react'
import * as ApiClient from "../services/ApiClient"
import AutocompleteOverlay from "./AutocompleteOverlay"

import './CommandPrompt.css'

const CommandPrompt = () => {
    const [cmdline, setCmdline] = useState('')

    const executeCommand = async (params: KeyboardEvent<HTMLInputElement>) => {
        if (params.code !== 'Enter' || cmdline.length === 1) return

        let cmd = cmdline
        if (!cmd.startsWith('/') && cmd.length === 0) {
            printMessage('looks like nothing recognisable')
            setTimeout(() => setCmdline(''), 500)
            return
        }

        const operandes = cmd.split(' ')
        if (operandes[0] === '/unique'
            || operandes[0] === '/u') {
            
            await ApiClient.AddTask({
                _id: undefined,
                _rev: undefined,
                name: cmd.replace('/unique', '').replace('/u', ''),
                type: 'unique',
                timeblock: 60,
                description: 'Description ...',
                priority: 1,
                repeat: 1,
            })
            printMessage(`Unique task added in task pool !`)
        } 
        else if (operandes[0] === '/interruption'
            || operandes[0] === '/i') {
                
            await ApiClient.AddTask({
                _id: undefined,
                _rev: undefined,
                name: cmd.replace('/interruption', '').replace('/i', ''),
                type: 'interruption',
                timeblock: 60,
                description: 'Description ...',
                priority: 1,
                repeat: 1,
            })
            printMessage(`Interruption added in task pool !`)
        }
        else { 
            await ApiClient.AddTask({
                _id: undefined,
                _rev: undefined,
                name: cmd,
                type: 'unique',
                timeblock: 60,
                description: 'Description ...',
                priority: 1,
                repeat: 1,
            })
            printMessage(`Unique task added in task pool !`)
        }
    }

    const printMessage = (msg: string) => {
        setCmdline(msg)
        setTimeout(() => setCmdline(''), 700)
    }

    const onClear = () => {
        setCmdline('')
    }
    
    useEffect(() => {
        const autoSuggest = [
            "/unique",
            "/u",
            "/interruption",
            "/i",
        ]
        AutocompleteOverlay(document.getElementById('cmdInput1'), autoSuggest)
    }, [])

    return (
        <div className="commandprompt-container">
            <input className="input-start"
                autoComplete="off"
                id="cmdInput1"
                type="text"
                placeholder="type name to create a task"
                onChange={(e) => setCmdline(e.target.value)}
                value={cmdline}
                onKeyUp={executeCommand}
                onBlur={onClear} />
        </div>
    )
}

export default CommandPrompt

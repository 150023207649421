export const GetHours = (start: Date, end: Date): number[] => {
  const result = []
  for (let i = start.getHours(); i <= end.getHours(); i++) {
    if(i > 23) i = 0;
    result.push(i)
  }
  return result
}

export const MinutesTo = (start: Date, end: Date): number => {
  let result = (GetHours(start, end).length - 1) * 60
  result += end.getMinutes()

  return result
}

export const GetWeekNumber = ():number => {
    var date = new Date()
    date.setHours(0, 0, 0, 0)
    // Thursday in current week decides the year.
    date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7)
    // January 4 is always in week 1.
    var week1 = new Date(date.getFullYear(), 0, 4)
    // Adjust to Thursday in week 1 and count number of weeks from date to week1.
    return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000 - 3 + (week1.getDay() + 6) % 7) / 7)
}

export const GetDayOfYear = ():number => {
  var now:any = new Date()
  var start:any = new Date(now.getFullYear(), 0, 0)
  var diff = (now - start) + ((start.getTimezoneOffset() - now.getTimezoneOffset()) * 60 * 1000)
  var oneDay = 1000 * 60 * 60 * 24
  var day = Math.floor(diff / oneDay)
  return day
}

export default GetHours

import * as T from "../Types"

export const GetBaseStarterPacks = () => {
    const baseStarters:T.Blueprint[] = [
        {
            _id: "base-1",
            _rev: "1",
            name: "Start you day",
            description: "Good start for your day",
            imgUrl: "/imgs/basestarter-1.jpg",
            tasks: [
                {
                    name: "Machine Learning Learning",
                    type: "recurrent",
                    timeblock: 30,
                    description: "Good start from here : https://www.pluralsight.com/browse/machine-learning",
                    priority: -1,
                    repeat: 5,
                }
            ],
            projects: []
        },{
            _id: "base-2",
            _rev: "2",
            name: "Use your Brilliant subscription",
            description: "After all you paid for it right ? Block some time for it.",
            imgUrl: "/imgs/basestarter-2.jpg",
            tasks: [
                {
                    name: "Brilliant",
                    type: "practice",
                    timeblock: 30,
                    description: "Do a daily problem here https://brilliant.org/daily-problems/ or start a course here https://brilliant.org/courses/",
                    priority: 1,
                    repeat: 5,
                }
            ],
            projects: []
        },{
            _id: "base-3",
            _rev: "3",
            name: "Discovery Unreal Engine 5",
            description: "Hot right now, lets see if there's a opportunity there",
            imgUrl: "/imgs/basestarter-3.jpg",
            tasks: [
                {
                    name: "UE 5 Nanite",
                    type: "discovery",
                    timeblock: 90,
                    description: "https://www.unrealengine.com/en-US/learn",
                    priority: 1,
                    repeat: 5,
                },
                {
                    name: "UE 5 Lumen",
                    type: "discovery",
                    timeblock: 90,
                    description: "https://www.unrealengine.com/en-US/learn",
                    priority: 1,
                    repeat: 5,
                }
            ],
            projects: []
        }
    ]

    return baseStarters
}

export default GetBaseStarterPacks
import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import * as ApiClient from '../services/ApiClient'
import CommandPrompt from '../components/CommandPrompt'

const Header = () => {
    const [devPreviewMode, setDevPreviewMode] = useState(false)
    
    useEffect(() => {
        const fetchUserSettings = async () => {
            const userSettings = await ApiClient.GetUserSettings()
            setDevPreviewMode(userSettings.showInDevFeatures)
        }

        fetchUserSettings()
    }, [])

    return (
        <>
            <header className='header'>
                <h1>Live Planner</h1> 
                {devPreviewMode ?? <CommandPrompt/>}
                <ul>
                    <li><Link className='headerlink' to="/now">NOW</Link></li>
                    <li><Link className='headerlink' to="/week">WEEK</Link></li>
                    <li><Link className='headerlink' to="/taskpool">POOL</Link></li>
                    <li><Link className='headerlink' to="/debug">DEBUG</Link></li>
                    <a className='headerlink' target="_blank" href="https://www.notion.so/L-I-V-E-P-L-A-N-N-E-R-73834ff394a5472f8203b69fc7bdfca8">BUGS</a>
                    {devPreviewMode ?? <li><Link className='headerlink' to="/blueprints">BLUEPRINTS</Link></li>}
                    <li><Link className='headerlink' to="/settings">SETTINGS</Link></li>
                </ul>
            </header>
            <div className='headerseparator'></div>
        </>
    )
}

export default Header

import { useState, useRef } from "react"
import { TaskViewModel } from './../TaskViewModel'
import { FaTimes, FaPlus, FaMinus, FaCheckCircle } from 'react-icons/fa'

import { useStoreActions, Actions } from "easy-peasy"
import { StoresModel } from "./../../../Stores"
import { useHistory } from "react-router-dom"

const QuickTaskingLineItem = ({
  vm,
}: {
  vm: TaskViewModel
}) => {
  // Global actions
  const removeTask = useStoreActions((actions: Actions<StoresModel>) => actions.TaskPoolView.removeTask)
  const updateTask = useStoreActions((actions: Actions<StoresModel>) => actions.TaskPoolView.updateTask)

  // Local states
  const [line, setLine] = useState(vm.task.name)
  const marginLeft = 10 + vm.depth * 20
  const history = useHistory();
  const inputRef:any = useRef();
  
  // Action
  const navigateToDetail = () => {
    history.push(`/taskdetails`, { id: vm.task._id })
  }

  const onKeyDown = (params: any) => {
    if (params.code === "Enter") {
      params.preventDefault()
      inputRef!.current.blur()
    } else if (params.code === "Tab") {
      params.preventDefault()
      inputRef!.current.blur()
    }
  }

  const typecss = vm.task.type.toLowerCase() === 'recurrent' ? 'recurrent' 
  : vm.task.type.toLowerCase() === 'discovery' ? 'discovery' 
  : vm.task.type.toLowerCase() === 'unique' ? 'unique' 
  : vm.task.type.toLowerCase() === 'interruption' ? 'interruption' : 'practice'

  let styleName = `quicktasking-line-container quicktasking-line-container-${typecss}`

  const onBlurUpdateTitle = () => {
    if (line !== vm.task.name){
      vm.task.name = line;
      updateTask(vm);
    }
  }
    
  return (
    <div className={styleName} onDoubleClick={() => navigateToDetail()}>
      {vm.done &&
        <>
          <h6>DONE </h6>
          <FaCheckCircle color="green"/>
        </>
      }
      <input
        style={{ paddingLeft: marginLeft }}
        className="quicktasking-line-input"
        type="text"
        placeholder="Type your task"
        value={line}
        ref={inputRef}
        onKeyDown={onKeyDown}
        onChange={(e) => setLine(e.target.value)}
        onBlur={() => onBlurUpdateTitle()}
        autoFocus={!vm.isCreationValidated}
      />

      <div className="quicktasking-control-container">
        <FaPlus className="quicktasking-controls" onClick={() => {vm.task.repeat += 1; updateTask(vm);}}/>
        <h6>{vm.ran}/{vm.task.repeat} sessions</h6>
        <FaMinus className="quicktasking-controls" onClick={() => {vm.task.repeat -= 1; updateTask(vm);}}/>
      </div>

      <div className="quicktasking-control-container">
        <FaPlus className="quicktasking-controls" onClick={() => {vm.task.priority += 1; updateTask(vm);}}/>
        <b className="fixed-width">P{vm.task.priority}</b>
        <FaMinus className="quicktasking-controls" onClick={() => {vm.task.priority -= 1; updateTask(vm);}}/>
      </div>

      <div className="quicktasking-control-container">
        <FaPlus className="quicktasking-controls" onClick={() => {vm.task.timeblock += 15; updateTask(vm);}}/>
        <h6>{vm.task.timeblock} min</h6>
        <FaMinus className="quicktasking-controls" onClick={() => {vm.task.timeblock -= 15; updateTask(vm);}}/>
      </div>
      <FaTimes className='quicktasking-controls quicktasking-control-container' onClick={() => {removeTask(vm)}}/>
    </div>
  )
}

export default QuickTaskingLineItem

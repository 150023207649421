export class Task {
    _id?: string
    _rev?: string = ''
    name: string = ''
    description: string = ''
    type: string = ''
    timeblock: number = 60
    repeat: number = 1
    priority: number = 3

    public constructor(
        fields?: {
            name?: string
            description?: string
            type?: string
            timeblock?: number
            repeat?: number
            priority?: number
        }){
        if (fields){
            Object.assign(this, fields)
        }
    }
}

export const TaskStatus = {
	done: "done",
	failed: "failed",
	inprogress: "inprogress",
	planned: "planned",
}

export interface Blueprint{
    _id?: string
    _rev?: string
    name: string
    description: string
    imgUrl: string
    projects: Project[]
    tasks: Task[]
}

export interface Timeblock {
    _id?: string
    _rev?: string
    name: string
    description: string
    type: string
    timeblock: number
    status: string
    taskId: string
    order: number
    weekNumber: number
    dayNumber: number
}

export const CreateTimeblockFromTask = (t:Task):Timeblock =>{
    return { 
        name: t.name,
        description: t.description,
        type: t.type,
        timeblock: t.timeblock,
        status: TaskStatus.planned,
        taskId: t._id,
        order: 0,
        weekNumber: 0,
        dayNumber: 0,
    } as Timeblock
}

export interface ClosedTimeblock extends Timeblock {
}

export const CreateClosedTimeblockFromTimeblock = (t:Timeblock):ClosedTimeblock =>{
    return { 
        name: t.name,
        description: t.description,
        type: t.type,
        timeblock: t.timeblock,
        status: t.status,
        taskId: t.taskId,
        order: t.order,
        weekNumber: t.weekNumber,
        dayNumber: t.dayNumber,
    } as ClosedTimeblock
}

export class UserSettings { 
    _id?: string
    _rev?: string = ''
    // Work hours
    preferedWorkHoursStart: number = 9
    preferedWorkHoursEnd: number = 17
    preferedWorkHoursPerDay: number = 7
    // General Settings
    showInDevFeatures: boolean = false
    autoAddLunchTime: boolean = false
    // Application UI States
    currentTaskSwitchViewIndex: number = 0

    public constructor(
        fields?: {
            workHoursStart?: number,
            workHoursEnd?: number,
            preferedWorkHoursEnd?: number,
            preferedWorkHoursPerDay?: number,
            currentTaskSwitchViewIndex?: number,
        }) {
        if (fields) {
            Object.assign(this, fields)
        }
    }
}

export interface Project {
    _id?: string
    _rev?: string
    name: string
    description: string
    imageUrl: string
    type: string
    taskIds: string[]
}

export class WorkHours {
    workHoursStart: number = 0
    workHoursEnd: number = 0
}

export class PlanState {
    _id?: string
    _rev?: string
    hoursStart: number = 9
    hoursEnd: number = 17
    timeblocks: Timeblock[] = []
}

export interface Export {
    taskpool: Task[]
    closedtimeblocks: ClosedTimeblock[]
    projects: Project[]
    usersettings: UserSettings
}

export {}
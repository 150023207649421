import { useState } from "react"
import { FaCheck, FaPlus, FaMinus } from "react-icons/fa"
import './QuickAdd.css'
import * as T from "../../../Types"

import { useStoreActions, Actions } from "easy-peasy"
import { StoresModel } from "./../../../Stores"

const QuickAdd = () => {
  // Global actions
  const addTask = useStoreActions((actions: Actions<StoresModel>) => actions.TaskPoolView.addTask)

  // Local states
  const [line, setLine] = useState('')
  const [sessions, setSessions] = useState(1)
  const [priority, setPriority] = useState(1)
  const [timeblock, setTimeblock] = useState(30)

  const onKeyDown = (params: any) => {
    if (params.code === "Enter") {
      params.preventDefault();
      onClickAddTask();
    }
  }

  const onClickAddTask = async () => {
    await addTask(new T.Task({
        name: line,
        type: "Recurrent",
        timeblock: timeblock,
        description: "Description ...",
        priority: priority,
        repeat: sessions,
      }))
    setLine('');
  }

  return (
    <div className="quicktasking-add-container">
      <input
        className="quicktasking-line-input"
        type="text"
        placeholder="Type your task name and enter to create"
        value={line}
        onKeyDown={onKeyDown}
        onChange={(e) => setLine(e.target.value)}
      />

      <div className="quicktasking-control-container">
        <FaPlus
          onClick={() => setSessions(sessions + 1)}
        />
        <h6>
          {sessions} sessions
        </h6>
        <FaMinus
          onClick={() => setSessions(sessions - 1)}
        />
      </div>

      <div className="quicktasking-control-container">
        <FaPlus
          onClick={() => setPriority(priority + 1)}
        />
        <b className="fixed-width">P{priority}</b>
        <FaMinus
          onClick={() => setPriority(priority - 1)}
        />
      </div>

      <div className="quicktasking-control-container">
        <FaPlus
          onClick={() => setTimeblock(timeblock + 15)}
        />
        <h6>{timeblock} min</h6>
        <FaMinus
          onClick={() => setTimeblock(timeblock - 15)}
        />
      </div>
      <FaCheck
        className="quicktasking-control-container"
        onClick={onClickAddTask}
      />
    </div>
  )
}

export default QuickAdd

import './ComingSoonCard.css'

const ComingSoonCard = () => {
    return (
        <div className="login-card">
            <h1>Coming Soon</h1>
            <h5>Your fully automated personal planner is not yet ready.</h5>

            <div className='takeatour-container'>
                <p>Want to learn about Live Planner, its vision, its features, and more? Take a look a the product page.</p>
                <input type='submit' className='button button-block login-btn' onClick={e => window.location.href='https://www.liveplanner.io'} value='Know more about Live Planner'/>
            </div>
        </div>
    )
}

export default ComingSoonCard

import { useState, useEffect } from "react"
import * as ApiClient from "../../services/ApiClient"
import * as T from "../../Types"
import WeeklyQuota from "./components/WeeklyQuota"
import QuickTasking from "./components/QuickTasking"
import QuickAdd from "./components/QuickAdd"
import "./TaskPoolView.css"

import { useStoreState, useStoreActions, State, Actions } from "easy-peasy"
import { StoresModel } from "./../../Stores"
import { TaskViewModel } from "./TaskViewModel"

export interface ProjectViewModel {
  listId: number
  project: T.Project
  taskVMs: TaskViewModel[]
}

const TaskPoolView = () => {
  const [projectVMs, setProjectVMs] = useState<ProjectViewModel[]>([])
  const [devPreviewMode, setDevPreviewMode] = useState<boolean>(false)
  const [preferredWorkHours, setPreferredWorkHours] = useState<T.WorkHours>()

  // States
  const userSettings = useStoreState((state: State<StoresModel>) => state.TaskPoolView.userSettings)
  const taskVMs = useStoreState((state: State<StoresModel>) => state.TaskPoolView.taskVms)

  // Effects
  const initialize = useStoreActions((actions: Actions<StoresModel>) => actions.TaskPoolView.initialize)

  // Actions

  // Load taskVMs
  // Effects
  useEffect(() => {
    initialize();
  }, [initialize])

  // Load projects
  // useEffect(() => {
  //   console.log('Fetch Projects')
  //   const fetchProjects = async () => {
  //     const storedProjects = await ApiClient.GetProjects()
  //     const noProjectPlaceHolder:T.Project = {
  //       _id: undefined,
  //       _rev: undefined,
  //       name: 'Not attached to objectives',
  //       description: '',
  //       imageUrl: '',
  //       type: '',
  //       taskIds: [],
  //     }

  //     const projects = [noProjectPlaceHolder, ...storedProjects]
  //     const projectVMs = projects.map((p, index) => { 
  //       const currentTaskVMs:TaskViewModel[] = []
  //       if (p.taskIds){
  //         p.taskIds.forEach((id) => {
  //           const task = taskVMs.find(f => f.task._id === id)
  //           if (task) {
  //             currentTaskVMs.push(task)
  //           }
  //         })
  //       }
  //       const vm:ProjectViewModel = { 
  //         listId: index, 
  //         project: p, 
  //         taskVMs: currentTaskVMs 
  //       }
  //       return vm
  //     })

  //     setProjectVMs(projectVMs)
  //     console.log(projectVMs)
  //     /// YOU ARE HERE FOR LP Quick Tasking
  //   }
  //   fetchProjects()
  // }, [taskVMs])

  // Load userSettings
    useEffect(() => {
      const fetchHours = async () => {
        const userSettings = await ApiClient.GetUserSettings()
        const preferredWorkHours = Object.create(T.WorkHours);
        preferredWorkHours.workHoursStart = userSettings.preferedWorkHoursStart;
        preferredWorkHours.workHoursEnd = userSettings.preferedWorkHoursEnd;
        setPreferredWorkHours(preferredWorkHours);

        setDevPreviewMode(userSettings.showInDevFeatures)
      }
      fetchHours();
    }, [])

  const archiveDones = () => {
    const dones = taskVMs.filter((t) => t.done)
    dones.forEach(async (t) => await ApiClient.ArchiveTask(t.task))
    // setTaskVMs(taskVMs.filter((t) => !t.done))
  }

  return (
    <div className="container">
      
      {devPreviewMode && taskVMs && preferredWorkHours && (
        <WeeklyQuota tasks={taskVMs} hours={preferredWorkHours} />
      )}

      <div>
        <div className="individualtasks-section">
          <h2>Objectives</h2>
          {/* <input type="submit" className="button button-sub-header2" onClick={navigateToTaskPoolReview} value="Start review"/> */}
          <input type="submit" className="button button-sub-header2" onClick={archiveDones} value="Remove done"/>
        </div>

        <QuickAdd />

        {taskVMs && projectVMs !== undefined &&(
          <QuickTasking
            vms={taskVMs}
          />
        )}
      </div>
    </div>
  )
}

export default TaskPoolView

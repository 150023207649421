import * as T from '../Types'
import { default as PouchDB } from 'pouchdb'

const tasksDB = new PouchDB('Tasks')
const quickPlanTasksDB = new PouchDB('QuickPlanTasks')
const blueprintsDB = new PouchDB('Blueprints')
const archiveTasksDB = new PouchDB('ArchivedTasks')
const closedTimeblocksDB = new PouchDB('ClosedTimeblocks')
const projectsDB = new PouchDB('Projects')
const settingsDB = new PouchDB('Settings')
const planStatesDB = new PouchDB('PlanStates')

/// Administration

export const CleanUpDatabase = async ():Promise<void> => {
  await tasksDB.compact()
  await quickPlanTasksDB.compact()
  await blueprintsDB.compact()
  await archiveTasksDB.compact()
  await closedTimeblocksDB.compact()
  await projectsDB.compact()
  await settingsDB.compact()
  await planStatesDB.compact()
}

export const DeleteDatabase = async ():Promise<void> => {
  await tasksDB.destroy()
  await quickPlanTasksDB.destroy()
  await blueprintsDB.destroy()
  await archiveTasksDB.destroy()
  await closedTimeblocksDB.destroy()
  await projectsDB.destroy()
  await settingsDB.destroy()
  await planStatesDB.destroy()
}

/// Projects
export const GetProjects = async ():Promise<T.Project[]> => {
  const all = await projectsDB.allDocs<T.Project>({
    include_docs: true,
    limit: 100
  })
  
  const results = all.rows.map(t => t.doc as T.Project)
  return results
}

export const AddProject = async (project:T.Project):Promise<T.Project> => {
  var resp = await projectsDB.post(project)
  project._rev = resp.rev
  project._id = resp.id
  return project
}

export const DeleteProject = async (id:string):Promise<boolean> => {
  const docToRemove = await projectsDB.get(id.toString())
  const result = await projectsDB.remove(docToRemove)
  return result.ok
}

export const UpdateProject = async (project:T.Project):Promise<T.Project> => {
  const resp = await projectsDB.put(project)
  project._rev = resp.rev
  return project
}

/// Closed timeblocks
export const GetClosedTimeblocks = async ():Promise<T.ClosedTimeblock[]> => {
  const all = await closedTimeblocksDB.allDocs<T.ClosedTimeblock>({
    include_docs: true,
    limit: 100
  })
  
  const results = all.rows.map(t => t.doc as T.ClosedTimeblock)
  return results
}

export const AddClosedTimeblock = async (timeblock:T.ClosedTimeblock):Promise<T.ClosedTimeblock> => {
  var result = await closedTimeblocksDB.post(timeblock);
  timeblock._rev = result.rev;
  timeblock._id = result.id;
  console.log(timeblock);
  return timeblock
}

export const DeleteClosedTimeblock = async (id:string):Promise<boolean> => {
  const docToRemove = await closedTimeblocksDB.get(id.toString())
  const result = await closedTimeblocksDB.remove(id.toString(), docToRemove._rev)
  return result.ok
}

export const UpdateClosedTimeblock = async (timeblock:T.ClosedTimeblock):Promise<T.ClosedTimeblock> => {
  const resp = await closedTimeblocksDB.put(timeblock)
  timeblock._rev = resp.rev
  return timeblock
}

/// Task Pool 
export const GetTask = async (id: string):Promise<T.Task> => {
  const document = await tasksDB.get<T.Task>(id)
  return document
}

export const GetTasks = async ():Promise<T.Task[]> => {
  const all = await tasksDB.allDocs<T.Task>({
    include_docs: true,
    limit: 100
  })
  
  const results = all.rows.map(t => t.doc as T.Task)
  return results
}

export const AddTask = async (task:T.Task):Promise<T.Task> => {
  const resp = await tasksDB.post(task)
  task._id = resp.id
  task._rev = resp.rev
  return task
}

export const DeleteTask = async (id:string):Promise<boolean> => {
  const docToRemove = await tasksDB.get(id.toString())
  const result = await tasksDB.remove(docToRemove)
  return result.ok
}

export const UpdateTask = async (task:T.Task):Promise<T.Task> => {
  const resp = await tasksDB.put(task)
  task._rev = resp.rev
  return task
}

export const AddOrUpdateAllTasks = async (tasks:T.Task[]):Promise<T.Task[]> => {
  var result: T.Task[] = [];
  await tasks.map(async (t, i) => {
    var r : T.Task;  
    if (t._id === undefined) r = await AddTask(t);
    else r = await UpdateTask(t);
      result = [...result, r]; 
    });
  return result;
}

export const ArchiveTask = async (task:T.Task):Promise<boolean> => {
  if (task._id === undefined) return false; 

  const resp = await GetTask(task._id)
  if (resp._id === undefined) return false; 

  await archiveTasksDB.put(resp)
  const respDelete = await DeleteTask(resp._id)

  return respDelete
}

/// Blueprints 
export const GetBlueprint = async (id: string):Promise<T.Blueprint> => {
  const document = await blueprintsDB.get<T.Blueprint>(id)
  return document
}

export const GetBlueprints = async ():Promise<T.Blueprint[]> => {
  const all = await blueprintsDB.allDocs<T.Blueprint>({
    include_docs: true,
    limit: 100
  })
  
  const results = all.rows.map(t => t.doc as T.Blueprint)
  return results
}

export const AddBlueprint = async (blueprint:T.Blueprint):Promise<T.Blueprint> => {
  const resp = await blueprintsDB.post(blueprint)
  blueprint._id = resp.id
  blueprint._rev = resp.rev
  return blueprint
}

export const DeleteBlueprint = async (id:string):Promise<boolean> => {
  const docToRemove = await blueprintsDB.get(id.toString())
  const result = await blueprintsDB.remove(docToRemove)
  return result.ok
}

export const UpdateBlueprint = async (blueprint:T.Blueprint):Promise<T.Blueprint> => {
  const resp = await blueprintsDB.put(blueprint)
  blueprint._rev = resp.rev
  return blueprint
}


/// User Settings 
const AddUserSettings = async (userSetting:T.UserSettings):Promise<T.UserSettings> => {
  const result = await settingsDB.post(userSetting)
  userSetting._id = result.id
  userSetting._rev = result.rev
  return userSetting
}

export const GetUserSettings = async ():Promise<T.UserSettings> => {
  const documents = await settingsDB.allDocs<T.UserSettings>({
    include_docs: true
  })

  if (documents.total_rows === 0 || !documents.rows[0].doc) {
    const newSettings = new T.UserSettings()
    const result = await AddUserSettings(newSettings)
    console.log(result)
    return result
  }

  const result = documents.rows[0].doc as T.UserSettings
  return result
}

export const UpdateUserSettings = async (userSetting:T.UserSettings):Promise<T.UserSettings> => {
  const result = await settingsDB.put(userSetting)
  userSetting._rev = result.rev
  return userSetting
}

/// Today State
export const GetPlanState = async ():Promise<T.PlanState> => {
  const AddPlanState = async (PlanState:T.PlanState):Promise<T.PlanState> => {
    const result = await planStatesDB.post(PlanState)
    PlanState._id = result.id
    PlanState._rev = result.rev
    return PlanState;
  }

  const documents = await planStatesDB.allDocs<T.PlanState>({
    include_docs: true
  })

  if (documents.total_rows === 0 || !documents.rows[0].doc) {
    const newSettings = new T.PlanState()
    const result = await AddPlanState(newSettings)
    return result
  }

  const result = documents.rows[0].doc as T.PlanState
  return result
}

export const UpdatePlanState = async (PlanState:T.PlanState):Promise<T.PlanState> => {
  const result = await planStatesDB.put(PlanState)
  PlanState._rev = result.rev
  return PlanState
}

export default GetTasks;
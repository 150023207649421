import { useState, useEffect } from 'react'
import TaskDetails from '../../components/TaskDetails'
import * as ApiClient from '../../services/ApiClient'

const TaskDetailsView = ({location}) => {
    const id = location.state.id
    const [loaded, setLoaded] = useState(false)
    const [currentTask, setCurrentTask] = useState(null)

    useEffect(() => {
        const getTask = async () => {
            const task = await ApiClient.GetTask(id)
            setCurrentTask(task)
            setLoaded(true)
        }

        getTask()
    }, [id])

    const updateTask = async (task) => {
        const result = await ApiClient.UpdateTask(task)
        setCurrentTask(result)
    }
    
    return (
        <div>
            {loaded && <TaskDetails task={currentTask} updateTask={updateTask}/>}
        </div>
    )
}

export default TaskDetailsView

import { useState, useEffect } from 'react'
import * as ApiClient from '../../services/ApiClient'
import * as TimeblockService from '../../services/TimeblockService'

import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import Button from "@material-ui/core/Button"
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import "./SettingsView.css"

const SettingsView = () => {
    const [userSettings, setUserSettings] = useState(null)
    const [startHours, setStartHours] = useState(9)
    const [endHours, setEndHours] = useState(17)
    const [checkedShowInDevFeatures, setCheckedShowInDevFeatures] = useState(false);
    const [checkedManageLunchTime, setCheckedManageLunchTime] = useState(false);

    useEffect(() => {
        const fetchUserSettings = async () => {
            const userSettings = await ApiClient.GetUserSettings()
            setUserSettings(userSettings)
            setStartHours(userSettings.preferedWorkHoursStart)
            setEndHours(userSettings.preferedWorkHoursEnd)
            setCheckedShowInDevFeatures(userSettings.showInDevFeatures)
            setCheckedManageLunchTime(userSettings.autoAddLunchTime)
        }

        fetchUserSettings()
    }, []);

    const savePreferedWorkHours = async () => {
        const parseStart = parseInt(startHours, 10)
        const parseEnd = parseInt(endHours, 10)

        if (isNaN(parseStart)) {
            console.log('nope')
            return
        }
        if (isNaN(parseEnd)) {
            console.log('nope')
            return
        }

        const lastUserSettings = ApiClient.UpdateUserSettings({
            ...userSettings,
            preferedWorkHoursStart: parseStart,
            preferedWorkHoursEnd: parseEnd
        })
        
        setUserSettings(lastUserSettings);
    }

    const onInDevFeaturesChange = async (params) => {
        params.preventDefault()

        setCheckedShowInDevFeatures(params.target.checked)
        
        const lastUserSettings = await ApiClient.UpdateUserSettings({
            ...userSettings,
            showInDevFeatures : params.target.checked
        })

        setUserSettings(lastUserSettings);
    }

    const onAutoAddLunchTimeChange = async (params) => {
        params.preventDefault()

        setCheckedManageLunchTime(params.target.checked)
        
        const lastUserSettings = await ApiClient.UpdateUserSettings({
            ...userSettings,
            autoAddLunchTime : params.target.checked
        })

        setUserSettings(lastUserSettings);
    }

    const closeToday = async () => {
        await TimeblockService.CloseDay()
    }

    const cleanUpDatabase = async () => {
        await ApiClient.CleanUpDatabase()
    }

    const deleteDatabase = async () => {
        await ApiClient.DeleteDatabase()
        window.location.reload()
    }

    const exportDatabase = async () => {
        const exportedDB = await ApiClient.ExportDatabase()
        const json = JSON.stringify(exportedDB)
        const filename = 'LivePlannerExport' + new Date()

        var file = new Blob([json], { type: 'application/json' })
        if (window.navigator.msSaveOrOpenBlob) // IE10+
            window.navigator.msSaveOrOpenBlob(file, 'filename')
        else { // Others
            var a = document.createElement("a"),
                url = URL.createObjectURL(file)
            a.href = url
            a.download = filename
            document.body.appendChild(a)
            a.click()
            setTimeout(function () {
                document.body.removeChild(a)
                window.URL.revokeObjectURL(url)
            }, 0)
        }
    }

    const importDatabase = async () => {
        const pickerOpts = {
            types: [
                {
                    description: 'Json Database',
                    accept: {
                        'json/*': ['.json']
                    }
                },
            ],
            excludeAcceptAllOption: true,
            multiple: false
        }

        const [fileHandle] = await window.showOpenFilePicker(pickerOpts)
        const file = await fileHandle.getFile()
        const content = await file.text()
        const exportedDB = JSON.parse(content)
        await ApiClient.ImportDatabase(exportedDB)
    }

    return (
        <div className='container'>
            <h2>Settings</h2>
            {userSettings ? (
                <div>
                    <div className='statsdayoperation-container'>
                        <GridContainer>
                            <GridItem xs={12} sm={6} md={6}>
                                <div className='form-control-field'>
                                    <label>Prefered start work hours</label>
                                    <input type='text'
                                        className='form-control-input'
                                        value={startHours}
                                        onChange={(e) => { setStartHours(e.currentTarget.value) }}
                                        onBlur={(e) => { savePreferedWorkHours()}} />
                                </div>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6}>
                                <div className='form-control-field'>
                                    <label>Prefered end work hours</label>
                                    <input type='text'
                                        className='form-control-input'
                                        value={endHours}
                                        onChange={(e) => { setEndHours(e.currentTarget.value) }} 
                                        onBlur={(e) => { savePreferedWorkHours()}} />
                                </div>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6}>
                                <FormGroup>
                                    <FormControlLabel control={<Switch checked={checkedShowInDevFeatures} onChange={onInDevFeaturesChange} />} label="Show in dev features" />
                                </FormGroup>
                                <FormGroup>
                                    <FormControlLabel control={<Switch checked={checkedManageLunchTime} onChange={onAutoAddLunchTimeChange} />} label="Auto add lunch time" />
                                </FormGroup>
                            </GridItem>
                        </GridContainer>
                    </div>
                    <div className='statsdayoperation-container'>
                        <h2>Statistics and day operations</h2>
                        <GridContainer>
                            <GridItem xs={12} sm={6} md={6}>
                                {/* <Button variant='containedSecondary' fullWidth={true} onClick={resetToday}>Reset my day</Button>
                                <Button variant='containedSecondary' fullWidth={true} onClick={resetWeek}>Reset my week</Button> */}
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6}>
                                <Button variant='containedSecondary' fullWidth={true} onClick={closeToday}>Close my day</Button>
                            </GridItem>
                        </GridContainer>
                    </div>
                    <div className='localdatabase-container'>
                        <h2>Local Database</h2>
                        <GridContainer>
                            <GridItem xs={12} sm={6} md={6}>
                                <Button variant='containedSecondary' fullWidth={true} onClick={exportDatabase}>Export</Button>
                                <Button variant='containedSecondary' fullWidth={true} onClick={importDatabase}>Import</Button>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6}>
                                <Button variant='containedSecondary' fullWidth={true} onClick={cleanUpDatabase}>Compact</Button>
                                <Button variant='containedSecondary' fullWidth={true} onClick={deleteDatabase}>Reset</Button>
                            </GridItem>
                        </GridContainer>
                    </div>
                </div>
            ) : (
                <p>Loading...</p>
            )}
        </div>
    )
}

export default SettingsView

import * as T from '../Types'
import BlueprintListItem from './BlueprintListItem'
import './BlueprintsList.css'

const BlueprintsList = ({blueprints, onAdd}:{
    blueprints: T.Blueprint[]
    onAdd: (id:string) => void
}) => {
    return (
        <div className="blueprintslist">
            {blueprints.map(blueprint => <BlueprintListItem key={blueprint._id} blueprint={blueprint} onAdd={onAdd}/>)}
        </div>
    )
}

export default BlueprintsList
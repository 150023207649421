import LoginCard from '../../components/LoginCard'
import { useHistory } from "react-router-dom"

const LoginView = () => {
    const history = useHistory()

    const onLogin = () => {
        // Accept everything
        history.push("/tour")
    }

    const serverLessLogin = () => {
        history.push("/now")
    }
    
    return (
        <div className="login-container">
            <LoginCard onLogin={onLogin} onServerlessLogin={serverLessLogin}/>
        </div>
    )
}

export default LoginView
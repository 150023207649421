import { useState } from 'react'
import './LoginCard.css'
import { Link, useHistory } from 'react-router-dom'

const LoginCard = ({onLogin, onServerlessLogin}) => {
    const history = useHistory()

    const [login, setLogin] = useState('')
    const [password, setPassword] = useState('')
    const [isEmailErrorState, setIsEmailErrorState] = useState(false)
    const [isPassErrorState, setIsPassErrorState] = useState(false)

    const onSubmit = (params) => {
        params.preventDefault()

        setIsEmailErrorState(login.length === 0)
        setIsPassErrorState(password.length === 0)

        if (isEmailErrorState || isPassErrorState) return 

        history.push('/aboutthat')
    }

    const onTakeTourClick = (params) => {
        params.preventDefault()
        onLogin()
    }
    
    return (
        <div className="login-card">
            <h1>Live Planner</h1>
            <h5>Your fully automated personal planner.</h5>

            <div className='takeatour-container'>
                <p>Want to take a free look inside without registering ?</p>
                <input type='submit' className='button button-block login-btn' onClick={onTakeTourClick} value='Take a tour'/>
            </div>
            
            <div className='separator' />

            <div className='takeatour-container'>
                <p>Login without account ? Start a serverless session.</p>
                <input type='submit' className='button button-block login-btn' onClick={onServerlessLogin} value='Serverless Login'/>
            </div>

            <div className='separator' />

            <form onSubmit={onSubmit} className='form-control'>
                <div className='form-control-field'>
                    <label>Login</label>
                    <input type='text'
                           className={`form-control-input ${isEmailErrorState ? 'login-error' : ''}`}
                           value={login}
                           placeholder='email'
                           onChange={(e) => { 
                               setIsEmailErrorState(false)
                               setLogin(e.target.value)
                            }}/>
                </div>
                <div className='form-control-field'>
                    <label>Password</label>
                    <input type='password'
                           className={`form-control-input ${isPassErrorState ? 'login-error' : ''}`}
                           value={password}
                           placeholder='***'
                           onChange={(e) => {
                               setIsPassErrorState(false)
                               setPassword(e.target.value)
                            }}/>
                </div>

                <input type='submit' className='button button-block login-btn' value='Login'/>
            </form>
            <div className='login-footer-container'>
                <Link to="/aboutthat">Forgot password ?</Link>
                <Link to="/aboutthat">Register</Link>
            </div>
        </div>
    )
}

export default LoginCard

import TimeBlockTimelineItem from './TimeBlockTimelineItem'
import './TimeBlockTimeline.css'
import * as T from '../Types'

const TimeBlocksTimeline = ({state, decrease, increase, close, fail}
    :{ 
    state: T.PlanState
    decrease: (timeblock: T.Timeblock) => void
    increase: (timeblock: T.Timeblock) => void
    close: (id: T.Timeblock) => void
    fail: (id: T.Timeblock) => void
}) => {
    return (
        <div className='timeblocks-timeline'>
            {state.timeblocks && state.timeblocks.map((t, i) => 
                <TimeBlockTimelineItem key={i} timeblock={t} increase={increase} decrease={decrease} close={close} fail={fail} state={state}/>
            )}
        </div>
    )
}

export default TimeBlocksTimeline
import * as ApiClient from '../../services/ApiClient'
import { useState, useEffect } from 'react'
import BlueprintsList from '../../components/BlueprintsList'
import * as T from '../../Types'
import './BlueprintsView.css'

const BlueprintsView = () => {
    const [blueprints, setBlueprints] = useState<T.Blueprint[]>()

    useEffect(() => {
        const getBlueprints = () => {
            var bps = ApiClient.GetBlueprints()
            setBlueprints(bps)
        }
        
        getBlueprints()
    }, [])

    const instanciateBlueprint = (id:string) => {
        console.log('Coucou' + id)
    }
    
    return (
        <div className='container'>
            <h2>Starter Packs</h2>
            <div className='base-starter-pack-container'>
                {blueprints && <BlueprintsList blueprints={blueprints} onAdd={instanciateBlueprint}/>}
            </div>
        </div>
    )
}

export default BlueprintsView

import { useState } from 'react'
import { FaMicrosoft, FaGoogle} from 'react-icons/fa'

import TimeLine from '../../components/TimeLine'
import TimeBlocksTimeline from '../../components/TimeBlocksTimeline'
import TimeBlockDetails from '../../components/TimeBlockDetails'
import * as ApiClient from '../../services/ApiClient'

import './FirstTimeExperience.css'

const NowIntro = () => {
    const fakeCalculatedTimeBlocks = [
        {
            _id: "1",
            name: "Morning routine",
            description: "Check your mails etc...",
            type: "Recurrent",
            timeblock: 30,
            status: "done"
        },
        {
            _id: "2",
            name: "Pluralsight Javascript",
            type: "Recurrent",
            timeblock: 60,
            description: "Apprendre un peu d'IA sur pluralsight pour le plaisir.\nRetour à la ligne ?",
            status: "inprogress"
        },
        {
            _id: "3",
            name: "John's issue about x",
            description: "Parce que le gars fait des bonnes vidéos ! Nan mais c'est vrai c'est plutôt cool.",
            type: "Recurrent",
            timeblock: 30,
            status: "planned"
        },
        {
            _id: "4",
            name: "New employee checkup",
            description: "You cant afford to be late on this as it's hot right now and you could capitalise on this ! DOIT",
            type: "Discovery",
            timeblock: 30,
            status: "planned"
        },
        {
            _id: "5",
            name: "Discover that new security solution",
            description: "You cant afford to be late on this as it's hot right now and you could capitalise on this ! DOIT",
            type: "Discovery",
            timeblock: 120,
            status: "planned"
        },
        {
            _id: "6",
            name: "Training write for the blog",
            description: "You cant afford to be late on this as it's hot right now and you could capitalise on this ! DOIT",
            type: "Discovery",
            timeblock: 60,
            status: "planned"
        }
    ]

    const [startHours, setStartHours] = useState()
    const [endHours, setEndHours] = useState()
    const [selectedTimeblock, setSelectedTimeblock] = useState(fakeCalculatedTimeBlocks[0])
    const [workHours, setWorkHours] = useState({
        workHoursStart: 7,
        workHoursEnd: 15
    })
    const [timeblocks, setTimeblocks] = useState(null)

    const onSaveHours = async (params) => {
        params.preventDefault()
        let parseStart = parseInt(startHours, 10)
        let parseEnd = parseInt(endHours, 10)

        // NaN is the only value that is not equal to itself
        if (isNaN(parseStart)){
            parseStart = 7
        }
        if (isNaN(parseEnd)){
            parseEnd = 15
        }


        var savedUserSettings = await ApiClient.GetUserSettings()
        savedUserSettings.workHoursStart = parseStart
        savedUserSettings.workHoursEnd = parseEnd
        await ApiClient.UpdateUserSettings(savedUserSettings)
        setWorkHours(savedUserSettings)

        const currentCard = document.getElementById('ftecard-step-1')
        currentCard.remove()
        const nextCard = document.getElementById('ftecard-step-3')
        nextCard.style.display = 'block'
    }

    const onSaveTasks = (params) => {
        params.preventDefault()

        const currentCard = document.getElementById('ftecard-step-3')
        currentCard.remove()
        const nextCard = document.getElementById('ftecard-step-4')
        nextCard.style.display = 'block'
    }

    const onSyncAdded = (params) => {
        params.preventDefault()

        const currentCard = document.getElementById('ftecard-step-4')
        currentCard.remove()
        const fifth = document.getElementById('ftecard-step-5')
        fifth.style.display = 'block'
    }

    const onCalculateSampleDay = (params) => {
        params.preventDefault()

        const currentCard = document.getElementById('ftecard-step-5')
        currentCard.remove()
        setTimeblocks(fakeCalculatedTimeBlocks)
        setSelectedTimeblock(fakeCalculatedTimeBlocks[1])
    }

    const onCloseTimeblock = (id) => {
        const [currentTimeblock] = timeblocks.filter(t => t._id === id)
        currentTimeblock.status = 'done'

        if (timeblocks.indexOf(currentTimeblock) + 1 > timeblocks.length) {
            return
        }

        const next = timeblocks[timeblocks.indexOf(currentTimeblock) + 1]
        next.status = 'inprogress'

        setSelectedTimeblock(next)
    }

    const onSelectTimeblock = (id) => {
        const [focusedTimeblock] = timeblocks.filter(t => t._id === id)
        setSelectedTimeblock(focusedTimeblock)
    }
    
    
    return (
        <div className='container'>
            <TimeLine hours={workHours}/>
            <TimeBlocksTimeline hours={workHours} timeblocks={timeblocks} close={onCloseTimeblock} select={onSelectTimeblock}/>
            <TimeLine hours={workHours} labelColor='#ddd' indicatorPosition='bottom'/>

            {selectedTimeblock && <TimeBlockDetails timeblock={selectedTimeblock}/>}

            <div id='ftecard-step-1' className='ftecard-container ftecard-card'>
                <h1>Demo time !</h1>
                <p>Let's see what's Live Planner is about in a few minutes.</p>
                <br />
                <p>First this is the "Now" tab where you can see your current day planning. It's empty now.</p>
                <br />
                <p>Let's start by settings your work hours :</p>
                <br />
                <form onSubmit={onSaveHours} className='form-control'>
                    <div className='form-control-field'>
                        <label>Start work hours</label>
                        <input type='text'
                               className='form-control-input'
                               value={startHours}
                               placeholder='7'
                               onChange={(e) => {setStartHours(e.currentTarget.value)}}/>
                    </div>
                    <div className='form-control-field'>
                        <label>End work hours</label>
                        <input type='text'
                               className='form-control-input'
                               placeholder='15'
                               value={endHours}
                               onChange={(e) => {setEndHours(e.currentTarget.value)}}/>
                    </div>
                    <br />
                    <input type='submit' className='button button-block' value="Save hours"/>
                </form>
            </div>

            <div id='ftecard-step-3' className='ftecard-container ftecard-card' style={{display: 'none'}}>
                <h1>Noted !</h1>
                <p>Live Planner will try to accomodate tasks according to those work hours.</p>
                <br />
                <p>Finally let's add some sample tasks to your pool of work to do :</p>
                <br />
                <form onSubmit={onSaveTasks} className='form-control'>
                    <div className='form-control-field-checkbox ftecard-card-form-control-field-checkbox'>
                        <input type='checkbox'
                               className='form-control-input'
                               value={startHours}
                               onChange={(e) => {setStartHours(e.currentTarget.value)}}/>
                        <label><b>Morning routine</b> - 30min/day</label>
                    </div>

                    <div className='form-control-field-checkbox ftecard-card-form-control-field-checkbox'>
                        <input type='checkbox'
                               className='form-control-input'
                               value={startHours}
                               onChange={(e) => {setStartHours(e.currentTarget.value)}}/>
                        <label><b>Follow Pluralsight React videos</b> - 5x60min</label>
                    </div>

                    <div className='form-control-field-checkbox ftecard-card-form-control-field-checkbox'>
                        <input type='checkbox'
                               className='form-control-input'
                               value={startHours}
                               onChange={(e) => {setStartHours(e.currentTarget.value)}}/>
                        <label><b>New employee checkup</b> - 1x30min</label>
                    </div>

                    <div className='form-control-field-checkbox ftecard-card-form-control-field-checkbox'>
                        <input type='checkbox'
                            className='form-control-input'
                            value={startHours}
                            onChange={(e) => {setStartHours(e.currentTarget.value)}}/>
                        <label><b>John's issue about X</b> - 1x30min</label>
                    </div>

                    <div className='form-control-field-checkbox ftecard-card-form-control-field-checkbox'>
                        <input type='checkbox'
                            className='form-control-input'
                            value={startHours}
                            onChange={(e) => {setStartHours(e.currentTarget.value)}}/>
                        <label><b>Discover that new security solution</b> - 120min/week</label>
                    </div>

                    <div className='form-control-field-checkbox ftecard-card-form-control-field-checkbox'>
                        <input type='checkbox'
                            className='form-control-input'
                            value={startHours}
                            onChange={(e) => {setStartHours(e.currentTarget.value)}}/>
                        <label><b>Training write for the blog</b> - 60min/week</label>
                    </div>
                    <br />
                    <input type='submit' className='button button-block' value="Add tasks to the tasks pool"/>
                </form>
            </div>

            <div id='ftecard-step-4' className='ftecard-container ftecard-card' style={{display: 'none'}}>
                <h1>Tasks added !</h1>
                <p>Live Planner will plan your day picking into this tasks pool. There is more than just a name or time, like priority, effort or order</p>
                <br />
                <p>Oh I felt like checking all those tasks regardless of your choices.</p>
                <br />
                <p>Next we can synchronize Live Planner with your current calendar provider. This way Live Planner won't propose you tasks during your meetings or events.</p>
                <br />
                <div>
                    <FaMicrosoft /> Microsoft Outlook
                </div>
                <div >
                    <FaGoogle /> Google Calendar
                </div>
                <div >
                    <FaGoogle /> iCloud
                </div>
                <br />
                <input type='submit' onClick={onSyncAdded} className='button button-block' value="Maybe later (anyway cant sync yet)"/>
            </div>

            <div id='ftecard-step-5' className='ftecard-container ftecard-card' style={{display: 'none'}}>
                <h1>All set !</h1>
                <p>Let's see what your demo sample planning looks like !</p>
                <br />
                <input type='submit' className='button button-block' onClick={onCalculateSampleDay} value="Calculate the optimal planning"/>
            </div>

        </div>
    )
}

export default NowIntro

import { useState, useEffect } from "react"
import { Task } from "../Types"
import "./TaskDetails.css"

interface Props {
  task: Task
  updateTask: (task: Task) => void
}

const TaskDetails = ({ task, updateTask }: Props) => {
  const [nameEdit, setNameEdit] = useState(task.name)
  const [descriptionEdit, setDescriptionEdit] = useState(task.description)
  const [type, setType] = useState(task.type)
  const [priority, setPriority] = useState(task.priority)
  const [timeblock, setTimeblock] = useState(task.timeblock)
  const [repeat, setRepeat] = useState(task.repeat)

  const [isEditTitleMode, setIsEditTitleMode] = useState(false)
  const [isEditBodyMode, setIsEditBodyMode] = useState(false)

  const editNameMode = () => {
    setNameEdit(task.name)
    setDescriptionEdit(task.description)
    setIsEditTitleMode(true)
    setIsEditBodyMode(false)
  }

  const editDescriptionMode = () => {
    setNameEdit(task.name)
    setDescriptionEdit(task.description)
    setIsEditBodyMode(true)
    setIsEditTitleMode(false)
  }

  const editOut = () => {
    updateTask({
      ...task,
      name: nameEdit,
      description: descriptionEdit,
      type: type,
      timeblock: timeblock,
      priority: priority,
      repeat: repeat,
    })

    setIsEditBodyMode(false)
    setIsEditTitleMode(false)
  }

  // Auto height for textarea
  useEffect(() => {
    const updateTxtArea = (ev:any) => {
           ev.srcElement.style.height = "auto"
            ev.srcElement.style.height = ev.srcElement.scrollHeight + "px"
    }
    
    const tx = document.getElementsByClassName("task-details-body-textarea")[0]
    if (tx) {
      tx.setAttribute(
        "style",
        "height:" + tx.scrollHeight + "px;overflow-y:hidden;"
      )
      tx.addEventListener("input", updateTxtArea, false)
    }
  }, [isEditBodyMode])

  return (
    <div className="task-details-container">
      {!isEditTitleMode ? (
        <h1 onDoubleClick={editNameMode}>{task.name}</h1>
      ) : (
        <textarea
          className="task-details-title-textarea"
          value={nameEdit}
          onChange={(e) => setNameEdit(e.target.value)}
          onBlur={editOut}
        />
      )}

      <div className="task-details-parameters-container">
        <h6>Type</h6>
        <select
          name="dropdown"
          className="form-control-input"
          onBlur={editOut}
          value={type}
          onChange={(e) => setType(e.target.value)}
        >
          <option value="recurrent">Recurrent</option>
          <option value="unique">Unique</option>
          <option value="discovery">Discovery</option>
          <option value="practice">Deliberate practice</option>
          <option value="interruption">Interruption</option>
        </select>
        <h6>Priority</h6>
        <input
          type="text"
          className="form-control-input task-details-number-control-input"
          value={priority}
          placeholder="1"
          onChange={(e) => setPriority(parseInt(e.target.value))}
          onBlur={editOut}
        />
        <h6>TimeBlock</h6>
        <input
          type="text"
          className="form-control-input task-details-number-control-input"
          value={timeblock}
          placeholder="60, 45, 30..."
          onChange={(e) => setTimeblock(parseInt(e.target.value))}
          onBlur={editOut}
        />
        <h6>Repeat</h6>
        <input
          type="text"
          className="form-control-input task-details-number-control-input"
          value={repeat}
          placeholder="1, 2, 3..."
          onChange={(e) => setRepeat(parseInt(e.target.value))}
          onBlur={editOut}
        />
      </div>

      {!isEditBodyMode ? (
        task.description.split("\n").map((value, index) => {
          return (
            <div
              className="task-details-body"
              onDoubleClick={editDescriptionMode}
              key={index}
            >
              <label>{value}</label>
              <br />
            </div>
          )
        })
      ) : (
        <textarea
          className="task-details-body-textarea"
          value={descriptionEdit}
          onChange={(e) => setDescriptionEdit(e.target.value)}
          onBlur={editOut}
        />
      )}
    </div>
  )
}

export default TaskDetails

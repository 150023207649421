import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import './TimeLine.css'
import * as DateEx from '../services/DateEx'
import ArrowDownIcon from "@material-ui/icons/ArrowDropDown"
import ArrowUpIcon from "@material-ui/icons/ArrowDropUp"
import * as T from '../Types'

const TimeLine = ({labelColor, dashColor, indicatorPosition, margin, state}:{
    labelColor: string
    dashColor: string
    indicatorPosition: string
    margin: string
    state: T.PlanState
}) => {
    // Global state
    const workHoursStart = new Date(0, 0, 0, state.hoursStart, 0, 0, 0);
    let workHoursEnd = new Date(0, 0, 0, state.hoursEnd, 0, 0, 0);
    if (state.hoursEnd === 24) {
        workHoursEnd = new Date(0, 0, 0, 23, 59);
    }

    const [timeIndicatorPosition, setTimeIndicatorPosition] = useState('0%')

    const totalHours = DateEx.GetHours(workHoursStart, workHoursEnd)
    const lastHour = totalHours.pop();

    const updateIndicatorPosition = () => {
        const totalMinutes = DateEx.MinutesTo(workHoursStart, workHoursEnd)
        const minutesSinceStart = DateEx.MinutesTo(workHoursStart, new Date())
        let timeIndicatorComputedPosition = minutesSinceStart * 100/ totalMinutes
        
        if (timeIndicatorComputedPosition < 0) {
            timeIndicatorComputedPosition = 0
        } else if (timeIndicatorComputedPosition > 100) {
            timeIndicatorComputedPosition = 100
        }

        setTimeIndicatorPosition(timeIndicatorComputedPosition + '%')
    }
    
    useEffect(() => {
        updateIndicatorPosition()
        var interval = setInterval(updateIndicatorPosition, 60000)
        return () => clearInterval(interval)
    })

    return (
        <div className='timeline-container' style={{margin: margin}}>
            {indicatorPosition === 'top' && <ArrowDownIcon className='timeline-timeindicator' style={{'marginLeft': timeIndicatorPosition, color: 'green'}}/>}
            <div className='timeline'>
                {totalHours.map((h) => 
                    <React.Fragment key={h}>
                        <p className='timeline-hourlabel' style={{color: labelColor}}>{h}:00</p>
                        <div className='timeline-dash' style={{backgroundColor: dashColor}}></div>       
                    </React.Fragment>
                )}
                <div className='timeline-hourlabel' style={{color: labelColor}}>{lastHour}:00</div>
            </div>
            {indicatorPosition === 'bottom' && <ArrowUpIcon className='timeline-timeindicator' style={{'marginLeft': timeIndicatorPosition, color: 'green'}}/>}
        </div>
    )
}

TimeLine.propTypes = {
    labelColor: PropTypes.string,
    dashColor: PropTypes.string,
    margin: PropTypes.string,
    indicatorPosition: PropTypes.string,
}

TimeLine.defaultProps = {
    labelColor: '#555',
    dashColor: '#eee',
    margin: '0',
    indicatorPosition: 'top',
}

export default TimeLine
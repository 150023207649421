import { useState } from 'react'
import { FaMinus, FaPlus, FaCheck, FaTimes } from 'react-icons/fa'
import { useSpring, animated, config } from 'react-spring'
import * as DateEx from '../services/DateEx'
import * as T from '../Types'
import './TimeBlockTimeline.css'

const TimeBlockTimelineItem = ({timeblock, state, decrease, increase, close, fail}: { 
    timeblock: T.Timeblock
    state: T.PlanState
    decrease: (timeblock: T.Timeblock) => void
    increase: (timeblock: T.Timeblock) => void
    close: (timeblock: T.Timeblock) => void
    fail: (timeblock: T.Timeblock) => void
}) => {
    // Global state
    const workHoursStart = new Date(0, 0, 0, state.hoursStart, 0, 0, 0)
    let workHoursEnd = new Date(0, 0, 0, state.hoursEnd, 0, 0, 0)
    if (state.hoursEnd === 24) {
        workHoursEnd = new Date(0, 0, 0, 23, 59);
    }

    const totalMinutes = DateEx.MinutesTo(workHoursStart, workHoursEnd)
    const percentOfDay = timeblock.timeblock * 100 / totalMinutes

    const widthStyle = useSpring({
        flexBasis: `${percentOfDay}%`,
    })

    const statusStyle = timeblock.status === 'done' || timeblock.status === 'failed' || timeblock.status === 'filler' ? 'timeblock-disabled' 
                    : timeblock.status === 'inprogress' ? 'timeblock-inprogress' : ''

    // Animation
    const [flip, set] = useState(false)
    const pulseSpring = useSpring({
        to: { borderColor: '#a0a0a0' },
        from: { borderColor: '#fff' },
        reset: true,
        reverse: flip,
        delay: 1000,
        config: config.molasses,
        onRest: () => set(!flip),
    })

    let style = widthStyle; 
    if (timeblock.status === 'inprogress') {
        style = {...widthStyle, ...pulseSpring}
    }

    return (
        <animated.div className={`timeblock ${statusStyle}`} style={style}>
            <div className='timeblock-header'>
                <h4>{timeblock.name}</h4>
                <h6>{timeblock.type}</h6>
            </div>
            <div className='timeblock-body'>
                <p>{timeblock.description}</p>
            </div>

            {timeblock.status !== 'null' && (
            <div className='timeblock-footer'>
                {timeblock.status !== 'done' && timeblock.status !== 'delayed' && timeblock.status !== 'filler' &&
                <div className='timeblock-footer-timecontrol'>
                    <FaPlus onClick={() => increase(timeblock)}/>
                    <FaMinus onClick={() => decrease(timeblock)}/>
                    <h6>{timeblock.timeblock}</h6>
                </div>
                }
                {timeblock.status === 'inprogress' && 
                    <>
                        <FaCheck onClick={() => close(timeblock)}/>
                        <FaTimes onClick={() => fail(timeblock)}/>
                    </>
                }
                {timeblock.status === 'done' && <FaCheck color='green' />}
                {timeblock.status === 'failed' && <FaTimes color='red' />}
                {timeblock.status === 'delayed' && <FaTimes color='red' />}
            </div>
            )}
        </animated.div>
      )
}

export default TimeBlockTimelineItem

import { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import * as TimeblockService from "../../services/TimeblockService"
import * as T from "../../Types"
import TimeBlocksTimeline from "../../components/TimeBlocksTimeline"
import TimeLine from "../../components/TimeLine"
import TimeBlockDetails from "../../components/TimeBlockDetails"

import Button from "@material-ui/core/Button"
import ButtonGroup from '@material-ui/core/ButtonGroup';

import "./NowView.css"

import { useStoreState, useStoreActions, State, Actions } from "easy-peasy"
import { StoresModel } from "./../../Stores"

const NowView = () => {
  const history = useHistory()

  // States
  const userSettings = useStoreState((state: State<StoresModel>) => state.NowView.userSettings)
  const state = useStoreState((state: State<StoresModel>) => state.NowView.planState)
  const next = useStoreState((state: State<StoresModel>) => state.NowView.planState.timeblocks.filter(t => t.status === T.TaskStatus.inprogress)[0]);

  // Effects
  const fetchState = useStoreActions((actions: Actions<StoresModel>) => actions.NowView.fetchState)

  // Actions
  const saveTodayState = useStoreActions((actions: Actions<StoresModel>) => actions.NowView.saveTodayState)
  const closeTimeblock = useStoreActions((actions: Actions<StoresModel>) => actions.NowView.closeTimeblock)

  // Effects
  useEffect(() => {
    fetchState();
  }, [fetchState])

  // Generation logic
  const generateDayPlanning = async () => {
    const nextWorkHours = getLateHours(userSettings.preferedWorkHoursEnd - userSettings.preferedWorkHoursStart);
    const state = await TimeblockService.OrchestrateTimeblocksFromHours(
      nextWorkHours.workHoursStart,
      nextWorkHours.workHoursEnd
    );
    fetchState();
  }

  const generate1hourPlanning = async () => {
    const nextWorkHours = getLateHours(1);
    const state = await TimeblockService.OrchestrateTimeblocksFromHours(
      nextWorkHours.workHoursStart,
      nextWorkHours.workHoursEnd
    );
    fetchState();
  }

  const generate2hourPlanning = async () => {
    const nextWorkHours = getLateHours(2);
    const state = await TimeblockService.OrchestrateTimeblocksFromHours(
      nextWorkHours.workHoursStart,
      nextWorkHours.workHoursEnd
    );
    fetchState();
  }

  const generate4hourPlanning = async () => {
    const nextWorkHours = getLateHours(4);
    const state = await TimeblockService.OrchestrateTimeblocksFromHours(
      nextWorkHours.workHoursStart,
      nextWorkHours.workHoursEnd
    );
    fetchState();
  }

  const getLateHours = (length: number): T.WorkHours => {
    let start = new Date().getHours();
    let end = start + length;
    if (end > 23) end -= 24; 
    if (new Date().getMinutes() > 30) {
      start++
      end++
    }

    const result = new T.WorkHours();
    result.workHoursStart = start;
    result.workHoursEnd = end;
    return result;
  }
  // End generation logic

  const increaseTimeBlock = async (timeblock: T.Timeblock) => {
    timeblock.timeblock += 15;
    saveTodayState(state);
  }

  const decreaseTimeBlock = async (timeblock: T.Timeblock) => {
    timeblock.timeblock -= 15;
    saveTodayState(state);
  }

  const updateTimeblock = async (timeblock: T.Timeblock) => {
    saveTodayState(state);
  }

  const closeTimeblockClick = async (timeblock: T.Timeblock) => {
    timeblock.status = T.TaskStatus.done;
    await closeTimeblock(timeblock);
  }

  const failTimeblock = async (timeblock: T.Timeblock) => {
    timeblock.status = T.TaskStatus.failed;
    await closeTimeblock(timeblock);
  }

  const navigateToTaskDetails = (id: string) => {
    history.push("/taskdetails", { id: id });
  }

  return (
    <>
      <div className="container">
        <TimeLine state={state} />
        <TimeBlocksTimeline
          increase={increaseTimeBlock}
          decrease={decreaseTimeBlock}
          close={closeTimeblockClick}
          fail={failTimeblock}
          state={state}
        />
        <TimeLine
          state={state}
          labelColor="#ddd"
          indicatorPosition="bottom"
        />

        <div className="button-nowview-subtimeline-container">
          <ButtonGroup variant="contained" aria-label="outlined primary button group">
            <Button onClick={generate1hourPlanning} color="secondary">1h</Button>
            <Button onClick={generate2hourPlanning} color="secondary">2h</Button>
            <Button onClick={generate4hourPlanning} color="secondary">4h</Button>
            <Button onClick={generateDayPlanning} color="secondary">Day</Button>
          </ButtonGroup>
        </div>

        {next && (
          <TimeBlockDetails
            timeblock={next}
            updateTimeblock={updateTimeblock}
            gotoTaskDetails={navigateToTaskDetails}
          />
        )}
      </div>
    </>
  )
}

export default NowView

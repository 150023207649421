import Header from "./views/Header";
import NowView from "./views/Now/NowView";
import WeekView from "./views/Week/WeekView";
import TaskPoolView from "./views/TaskPool/TaskPoolView";
import TaskPoolReviewView from "./views/TaskPoolReview/TaskPoolReviewView";
import TaskDetailsView from "./views/TaskDetails/TaskDetailsView";
import BlueprintsView from "./views/Blueprints/BlueprintsView";
import ComingSoonView from "./views/ComingSoon/ComingSoonView";
import LoginView from "./views/Login/LoginView";
import SettingsView from "./views/Settings/SettingsView";
import DebugView from "./views/Debug/DebugView";
import AboutThatView from "./views/FeatureNotFound/AboutThatView";
import NowIntro from "./views/FirstTimeExperience/NowIntroView";
import Footer from "./views/Footer";

import { Route } from "react-router-dom";
import { useLocation } from "react-router";

import { StoreProvider, createStore } from "easy-peasy";
import { Stores, StoresModel } from "./Stores";

const App = () => {
  const store = createStore<StoresModel>(Stores);
  const location = useLocation();

  return (
    <StoreProvider store={store}>
      <div id="main-container">
        {!(location.pathname === "/login" || location.pathname === "/") ? <Header /> : <></>}

        <Route path={["/soon", "/"]} exact component={ComingSoonView} />
        <Route path="/login" component={LoginView} />
        <Route path="/now" component={NowView} />
        <Route path="/week" component={WeekView} />
        <Route path="/taskpool" component={TaskPoolView} />
        <Route path="/taskpoolreview" component={TaskPoolReviewView} />
        <Route path="/taskdetails" component={TaskDetailsView} />
        <Route path="/blueprints" component={BlueprintsView} />
        <Route path="/settings" component={SettingsView} />
        <Route path="/debug" component={DebugView} />
        <Route path="/aboutthat" component={AboutThatView} />
        <Route path="/tour" component={NowIntro} />

        {!(location.pathname === "/login" || location.pathname === "/")  ? <Footer /> : <></>}
      </div>
    </StoreProvider>
  );
};

export default App;
